<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-form-item
        :label="__('Transfer to phone number')"
        prop="blind_transfer_node.data.property_rules.data.transfer_phone_number"
        class="is-required"
      >
        <expression-input
          v-model="transferPhoneNumber"
          :complex-variables="complexVariables"
          :placeholder="
            __('Enter transfer phone number using the expression builder')
          "
          @edit-input="displayTransferPhoneNumberExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('ANI')"
        prop="blind_transfer_node.data.property_rules.data.ani"
      >
        <!-- eslint-disable-next-line -->
        {{ __("Please note: ANI modification feature is network dependent. Please consult your service provider") }}
        <expression-input
          v-model="ani"
          :complex-variables="complexVariables"
          :placeholder="__('Enter ANI using the expression builder')"
          @edit-input="displayAniExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Display Name')"
        prop="blind_transfer_node.data.property_rules.data.display_name"
      >
        <expression-input
          v-model="displayName"
          :complex-variables="complexVariables"
          :placeholder="__('Enter display name using the expression builder')"
          @edit-input="displayNameExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Transfer timeout (seconds)')"
        prop="blind_transfer_node.data.transfer_timeout"
        v-if="showEventHandler"
      >
        <el-select
          v-model="nodeToBind.blind_transfer_node.data.transfer_timeout"
          style="width: 100%;"
          default-first-option
          clearable
          :placeholder="__('Platform default (optional)')"
          @clear="
            () => {
              nodeToBind.blind_transfer_node.data.transfer_timeout = null;
            }
          "
        >
          <el-option
            v-for="(item, index) in transferTimeoutLimit"
            :label="item"
            :value="item"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('No answer / busy event handler')"
        prop="blind_transfer_node.data.no_answer_busy_canvas_id"
        v-if="showEventHandler"
      >
        <EventHandlers
          :event-handler-canvas="noAnswerEventHandlerCanvas"
          @update-event-handler="setNoAnswerEventHandlerCanvas($event)"
          :event-handler-place-holder="
            __('Select  or create a no answer or busy event handler canvas')
          "
          :show-event-handler-count="false"
        ></EventHandlers>
      </el-form-item>
      <el-form-item
        :label="__('Transfer target to caller')"
        prop="blind_transfer_node.data.invert_transfer_target"
        v-if="showEventHandler"
      >
        <el-checkbox
          v-model="nodeToBind.blind_transfer_node.data.invert_transfer_target"
          style="padding-left: 5px"
        ></el-checkbox>
      </el-form-item>
    </el-form>
    <expression-builder-dialog
      v-if="displayTransferPhoneNumberExpressionBuilderModal"
      v-model="transferPhoneNumber"
      :show-expression-builder="
        displayTransferPhoneNumberExpressionBuilderModal
      "
      @input="handleCancelTransferNumberExpressionBuilder"
      @cancel="handleCancelTransferNumberExpressionBuilder"
      @close="handleCancelTransferNumberExpressionBuilder"
    />

    <expression-builder-dialog
      v-if="displayAniExpressionBuilderModal"
      v-model="ani"
      :show-expression-builder="displayAniExpressionBuilderModal"
      @input="handleCancelAniExpressionBuilder"
      @cancel="handleCancelAniExpressionBuilder"
      @close="handleCancelAniExpressionBuilder"
    />
    <expression-builder-dialog
      v-if="displayNameExpressionBuilderModal"
      v-model="displayName"
      :show-expression-builder="displayNameExpressionBuilderModal"
      @input="handleCancelDisplayNameExpressionBuilder"
      @cancel="handleCancelDisplayNameExpressionBuilder"
      @close="handleCancelDisplayNameExpressionBuilder"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";

import { mapGetters } from "vuex";
import { numberOrVariable } from "@/utils/regex";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";

const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};

const initBlindTransferNode = {
  node_type: NODE_TYPES.BLIND_TRANSFER.NODE_TYPE,
  blind_transfer_node: {
    data: {
      property_rules: {
        data: {
          transfer_phone_number: "",
          display_name: "",
          ani: ""
        }
      },
      transfer_timeout: null,
      no_answer_busy_canvas_id: _.clone(eventHandlerCanvasInitialize),
      invert_transfer_target: false
    }
  }
};

export default {
  components: {
    ExpressionBuilderDialog,
    ExpressionInput,
    EventHandlers
  },
  mixins: [BaseNode],

  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(__("Transfer phone number is required"));
      } else if (_.isEmpty(JSON.parse(value))) {
        callback(__("Transfer phone number is required"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        blind_transfer_node: {
          data: {
            property_rules: {
              data: {
                transfer_phone_number: [
                  {
                    validator: validatePhoneNumber,
                    trigger: "blur"
                  }
                ]
              }
            }
          }
        }
      },
      variableRulesBkup: [],
      newVariableForAudioURL: 0,
      newVariableForAudioVar: 0,
      allowedRegex: numberOrVariable,
      transferTimeoutLimit: _.range(1, 121),
      displayTransferPhoneNumberExpressionBuilderModal: false,
      displayAniExpressionBuilderModal: false,
      displayNameExpressionBuilderModal: false
    };
  },
  computed: {
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    currentSelection() {
      return row => {
        console.log("currentSelection ", row);
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    noAnswerEventHandlerCanvas() {
      return this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id;
    },
    showEventHandler() {
      return (
        !_.isEmpty(JSON.parse(this.ani)) ||
        !_.isEmpty(JSON.parse(this.displayName))
      );
    },

    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.blind_transfer_node.data.property_rules.data
            .transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.blind_transfer_node.data.property_rules.data,
          "transfer_phone_number",
          expression
        );
      }
    },

    ani: {
      get() {
        return (
          this.nodeToBind.blind_transfer_node.data.property_rules.data.ani ||
          "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.blind_transfer_node.data.property_rules.data,
          "ani",
          expression
        );
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.blind_transfer_node.data.property_rules.data
            .display_name || "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.blind_transfer_node.data.property_rules.data,
          "display_name",
          expression
        );
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.blind_transfer_node)
    ) {
      this.initializeBridgeTransferNodeData();
    } else {
      if (
        this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.blind_transfer_node.data,
          "no_answer_busy_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noAnswerEventHandlerCanvas
          })
        );
      } else {
        this.$set(
          this.nodeToBind.blind_transfer_node.data,
          "no_answer_busy_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize)
        );
      }
    }
  },
  methods: {
    initializeBridgeTransferNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initBlindTransferNode)
      );
    },
    removeTranferTimeoutAndEventHandler() {
      this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id = null;
      this.nodeToBind.blind_transfer_node.data.transfer_timeout = null;
      this.nodeToBind.blind_transfer_node.data.invert_transfer_target = false;
    },

    handleCancelAniExpressionBuilder() {
      this.displayAniExpressionBuilderModal = false;
    },
    handleCancelTransferNumberExpressionBuilder() {
      this.displayTransferPhoneNumberExpressionBuilderModal = false;
    },
    handleCancelDisplayNameExpressionBuilder() {
      this.displayNameExpressionBuilderModal = false;
    },
    setNoAnswerEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.blind_transfer_node.data,
        "no_answer_busy_canvas_id",
        option
      );
    },

    cleanUpBlindTransferSettings() {
      if (!this.showEventHandler) {
        this.removeTranferTimeoutAndEventHandler();
      } else {
        if (
          !_.isEmpty(
            this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id,
            "canvas_name"
          ) &&
          this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id
            .canvas_name === ""
        ) {
          this.nodeToBind.blind_transfer_node.data.no_answer_busy_canvas_id = null;
        }
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method

      this.cleanUpBlindTransferSettings();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
